export const EVENTS = {
  TYPE: {
    TRACKEVENT: 'trackEvent',
    TRACKPAGEVIEW: 'trackPageView',
    ENABLELINKTRACKING: 'enableLinkTracking',
  },
  CATEGORIES: {
    REFERRAL: 'referral',
    PANELIST: 'panelist',
  },
  ACTIONS: {
    REFERRAL: {
      LINKCOPIED: 'referral-link-copied',
      PAGEVISIT: 'referral-page-visits',
    },
    PANELIST: {
      LOGGEDIN: 'logged-in',
    },
  },
  EXTRAINFO: {
    TWITTER: 'Twitter',
    GMAIL: 'Gmail',
    FACEBOOK: 'Facebook',
    COPYLINKBUTTON: 'CopyLinkButton',
  },
};
