import { useParams } from 'react-router-dom';
import { useClient } from '@splitsoftware/splitio-react';

import { EVENTS, FEATURE_FLAGS } from '@/lib';

import { useAuth } from './useAuth';

declare const _paq: any;

export function useTrackEvent() {
  const { panelGUID } = useParams();
  const { panelistId } = useAuth();
  const splitIOClient = useClient();
  const matomoEnabled =
    splitIOClient?.getTreatment(FEATURE_FLAGS.MATOMO_ENABLED) === 'on';
  const matomo = matomoEnabled ? _paq : { push: () => {} };

  function firstLogginSessionEvent() {
    matomo.push([
      EVENTS.TYPE.TRACKEVENT,
      EVENTS.CATEGORIES.PANELIST,
      EVENTS.ACTIONS.PANELIST.LOGGEDIN,
      JSON.stringify({
        panelistId,
        panelGuid: panelGUID,
      }),
      1,
    ]);
    matomo.push([EVENTS.TYPE.TRACKPAGEVIEW]);
    matomo.push([EVENTS.TYPE.ENABLELINKTRACKING]);
  }

  function sendReferralPageVisitsEvent() {
    matomo.push([
      EVENTS.TYPE.TRACKEVENT,
      EVENTS.CATEGORIES.REFERRAL,
      EVENTS.ACTIONS.REFERRAL.PAGEVISIT,
      JSON.stringify({
        panelistId,
        panelGuid: panelGUID,
      }),
      1,
    ]);
    matomo.push([EVENTS.TYPE.TRACKPAGEVIEW]);
    matomo.push([EVENTS.TYPE.ENABLELINKTRACKING]);
  }

  function sendCopyReferralLinkEvent(originType: string) {
    matomo.push([
      EVENTS.TYPE.TRACKEVENT,
      EVENTS.CATEGORIES.REFERRAL,
      EVENTS.ACTIONS.REFERRAL.LINKCOPIED,
      JSON.stringify({
        panelistId,
        panelGuid: panelGUID,
        originType,
      }),
      1,
    ]);
    matomo.push([EVENTS.TYPE.TRACKPAGEVIEW]);
    matomo.push([EVENTS.TYPE.ENABLELINKTRACKING]);
  }

  return {
    firstLogginSessionEvent,
    sendReferralPageVisitsEvent,
    sendCopyReferralLinkEvent,
  };
}
