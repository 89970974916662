import { Flex, useBreakpoint } from '@lucidhq/lucidium';
import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useAuth, useTrackEvent } from '@/hooks';

import { Footer } from './Footer';
import { Logo } from '../Logo';
import { Menu } from './Menu';

export function Layout({ children }: { children: JSX.Element }) {
  const isMobile = !useBreakpoint('600px');
  const { panelistId } = useAuth();
  const { pathname } = useLocation();
  const { firstLogginSessionEvent } = useTrackEvent();
  const [searchParams, setSearchParams] = useSearchParams();

  // Remove user_panel query param when navigating away from profile
  useEffect(() => {
    if (!pathname.includes('/profile') && searchParams.get('user_panel')) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        user_panel: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, setSearchParams]);

  useEffect(() => {
    if (panelistId) firstLogginSessionEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelistId]);

  return (
    <Flex
      lss={{
        flexDirection: 'column',
        backgroundColor: '#F8F8F8',
      }}
    >
      <Flex
        lss={{
          display: isMobile ? 'flex' : 'grid !important',
          gridTemplateColumns: '1.5fr 8fr 1fr',
          alignItems: isMobile ? 'center' : 'flex-end',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          padding: '0 20px',
        }}
      >
        <Flex lss={{ height: isMobile ? '35px' : '45px', alignSelf: 'center' }}>
          <Logo />
        </Flex>
        <Menu />
      </Flex>
      {children}
      <Footer />
    </Flex>
  );
}
